//var DEBUG = true;
var DEBUG = false;

var IGNORE_WARNINGS = true;
// var IGNORE_WARNINGS = false;

if(!DEBUG){
    if(!window.console) window.console = {};
    var methods = ["log", "debug", "warn", "info"];
    for(let i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}

if (DEBUG && IGNORE_WARNINGS){
    if(!window.console) window.console = {};
    var methods = ["warn"];
    for(let i=0;i<methods.length;i++){
        console[methods[i]] = function(){};
    }
}