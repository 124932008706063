import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";

const getMockConsumerData = () => {
    return {
        firstname: 'Ion',
        lastname: 'Iliescu',
        core_user_id: 983127414
    };
}

export default class MyMocks {    

    callAndDispatch(mockResponse, actionType) {
        let promise = new Promise(function(resolve, reject){
            if (mockResponse.success){
                resolve(mockResponse);                
            } else {
                reject(mockResponse);
            }
           
            dispatcher.dispatch({
                actionTypes: actionType,
                apiResponse: mockResponse
            });            
        });
        
        return promise;
    }

    getConsumerData() {       
        let successResponse = {
            success: true,
            data: {
                consumer: getMockConsumerData(),
                token: 123
            },
            timestamp: (new Date().getTime())
        };

        let errorResponse = {
            success: false,
            error_message: 'some_error',
            error_code: '1',
            timestamp: (new Date().getTime())
        };

        let mockResponse = errorResponse;
        mockResponse = successResponse;               

        return this.callAndDispatch(mockResponse, actionTypes.GET_CONSUMER_DATA);
    }
    
    setConsumerAppData(step, newData) {        
        let failedResponse = {
            success: false,
            error_message: 'Error setting new data for step ' + step
        }

        let successResponse = {
            success: true,            
            timestamp: (new Date().getTime())
        }

        let mockResponse = failedResponse;
        mockResponse = successResponse;

        return this.callAndDispatch(mockResponse, actionTypes.SET_CONSUMER_DATA);
    }    

}