import jquery from "jquery";

import dispatcher from "../appDispatcher";
import actionTypes from "./actionTypes";
import actionEndpoints from "./actionEndpoints";
import Mocks from "./mocks";

var myMocks = new Mocks();
// const useMocks = true;
const useMocks = false;

const apiEndpoint = actionEndpoints.SERVER;

const disclaimerStep = 2;
const termsStep = 8;

/* UTILITY FUNCITONS */

function apiCall(url, postData) {
    let promise = new Promise(function (resolve, reject) {
        jquery.post(
            url,
            postData
        )
            .done(function (res) {
                console.log('API RESPONSE: ', JSON.stringify(res));
                resolve(res);
            })
            .fail(function () {
                console.log('API SERVER failure...');
                let res = {
                    error_message: 'Nu am putut contacta serverul. Reincearca!'
                };
                reject(res);
            })
    });

    return promise;
}

function callAndDispatch(url, data, actionType) {
    let promise = new Promise(function (resolve, reject) {
        let response = null;

        apiCall(url, data).then(function (res) {
            response = res;
            if (res.success) {
                resolve(res);
            } else {
                reject(res);
            }
        }).catch(function (res) {
            response = res;
            reject(res);
        }).finally(function () {
            dispatcher.dispatch({
                actionTypes: actionType,
                apiResponse: response
            });
        });
    });

    return promise;
}

function checkRequiredFields(requiredFields, data) {
    let hasRequired = true;

    requiredFields.forEach((item) => {        
        if (!data.hasOwnProperty(item)){
            hasRequired = false;
        }
    });

    return hasRequired;
}

/* ACTIONS */

export function getConsumerData(inputToken) {
    if (useMocks) {
        return myMocks.getConsumerData();
    }

    let apiAction = actionEndpoints.GET_CONSUMER_DATA;
    let url = apiEndpoint + apiAction;

    let data = {
        token: inputToken
    };

    return callAndDispatch(url, data, actionTypes.GET_CONSUMER_DATA);
}

export function setConsumerAppData(step, newData) {
    switch (step){
        case disclaimerStep: // disclaimer page
            return setConsumerAppDataDisclaimerStep(newData);
        case termsStep: // confirmation page
            return setConsumerAppDataConfirmationStep(newData);
    }
}

export function setConsumerAppDataDisclaimerStep(newData) {
    let requiredFields = [
        'token', 'confirm'
    ];

    if (!checkRequiredFields(requiredFields, newData)) {
        return Promise.reject({ error_message: 'Nu au fost completate toate campurile necesare!' });
    }

    if (useMocks) {
        return myMocks.setConsumerAppData(disclaimerStep, newData);
    }

    let apiAction = actionEndpoints.SET_CONSUMER_DATA;
    let url = apiEndpoint + apiAction;

    let data = {
        token: newData['token'],
        data: {
            app_state: 2,
            app_data: {
                confirm : newData['confirm']
            }
        }
    };

    return callAndDispatch(url, data, actionTypes.SET_CONSUMER_DATA);
}

export function setConsumerAppDataConfirmationStep(newData) {
    if (!newData.signature){
        return Promise.reject({ error_message: 'Este nevoie sa introduceti semnatura!'});
    }

    let requiredFields = [
        'token', 
        'opt_in_marketing', 
        'opt_in_comm_sms', 
        'opt_in_comm_email', 
        'opt_in_comm_dm', 
        'opt_in_mkt_analysis', 
        // 'send_signup_email', 
        'signature'
    ];

    if (!checkRequiredFields(requiredFields, newData)) {
        return Promise.reject({ error_message: 'Nu au fost completate toate campurile necesare!'});
    }

    if (useMocks) {
        return myMocks.setConsumerAppData(termsStep, newData);
    }

    let apiAction = actionEndpoints.SET_CONSUMER_DATA;
    let url = apiEndpoint + apiAction;

    let data = {
        token: newData['token'],
        data: {
            app_state: 8,
            app_data: {
                opt_in_marketing : newData['opt_in_marketing'],
                opt_in_comm_sms : newData['opt_in_comm_sms'],
                opt_in_comm_email : newData['opt_in_comm_email'],
                opt_in_comm_dm : newData['opt_in_comm_dm'],
                opt_in_mkt_analysis: newData['opt_in_mkt_analysis'],
                // send_signup_email: newData['send_signup_email'],
                signature : newData['signature']
            }
        }
    };

    return callAndDispatch(url, data, actionTypes.SET_CONSUMER_DATA);
}