import { EventEmitter } from "events";
import dispatcher from "../appDispatcher";
import actionTypes from "../actions/actionTypes";
import errorCodes from "../utils/errorCodes";

const CHANGE_EVENT = "change";
let _token = null;
let _data = null;
let _noMoreAvailable = false;

class UserStore extends EventEmitter {
    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    getConsumerData() {
        return _data;
    }
    
    getToken() {
        return _token;
    }

    isContactClosed() {
        console.log('noMoreAvailable is', _noMoreAvailable);
        return _noMoreAvailable;
    }

    resetUserStoreData() {
        _token = null;
        _data = null;
        _noMoreAvailable = false;
    }
}

const store = new UserStore();

dispatcher.register((action) => {    
    console.log(JSON.stringify(action));    

    switch (action.actionTypes) {
        case actionTypes.GET_CONSUMER_DATA:
            if (action.apiResponse.success){
                _token = action.apiResponse.data.token;
                _data = action.apiResponse.data.consumer;  
            } else {
                if (action.apiResponse.error_message.indexOf('ERR_100') != -1){
                    console.log('noMoreAvailable');
                    _noMoreAvailable = true;
                }
            } 
            store.emitChange();
            break;        
            
        default:                                  
            store.emitChange();
    }
});

export default store;