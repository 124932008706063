import { useState, useEffect } from 'react';

import userStore from '../stores/userStore';
import { getConsumerData } from '../actions/actionRunner';

function useServerData() {

    const [token, setToken] = useState(userStore.getToken()); // is null initially as per userStore 
    const [data, setData] = useState(userStore.getConsumerData()); // is null initially as per userStore

    useEffect(() => {
        // when mounted / updated     
        // console.log('mounting');
        userStore.addChangeListener(onChangeData);
        if (userStore.getConsumerData() === null) { // if no status yet -> get status via API call            
            // getConsumerData().then(() => {                
            // }).catch(() => {                
            // });
        }

        // when unmounted
        return () => {     
            // console.log('unmounting');            
            userStore.removeChangeListener(onChangeData);
        }
    }, []);

    function onChangeData() {
        setData(userStore.getConsumerData());
        setToken(userStore.getToken());
    }

    return [token, data];
}

export default useServerData;